@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #252323;
}

a {
  padding: 0;
  margin: 0;
}

@screen ms {
  html {
    font-size: 18px;
  }
}

@screen mm {
  html {
    font-size: 18px;
  }
}

@screen ml {
  html {
    font-size: 18px;
  }
}

@screen t {
  html {
    font-size: 18px;
  }
}

@screen dxs {
  html {
    font-size: 12.6px;
  }
}

@screen ds {
  html {
    font-size: 13px;
  }
}

@screen dm {
  html {
    font-size: 13.5px;
  }
}

@screen dl {
  html {
    font-size: 15px;
  }
}

@screen dxl {
  html {
    font-size: 18px;
  }
}

@font-face {
  font-family: 'ABC Diatype';
  src: url('./resources/fonts/abc/ABCDiatype-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ABC Diatype';
  src: url('./resources/fonts/abc/ABCDiatype-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: 'ABC Diatype Mono';
  src: url('./resources/fonts/abc/ABCDiatypeMono-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Digital-7 Mono';
  src: url('./resources/fonts/digital-7/digital-7-mono.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Digital-7';
  src: url('./resources/fonts/digital-7/digital-7.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nes Controller';
  src: url('./resources/fonts/nes-controller/nes-controller.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'PressStart2P';
  src: url('./resources/fonts/Press_Start_2P/PressStart2P-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@keyframes opacity-animate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.popover-con {
  animation: opacity-animate 0.3s linear;
}

.fadeOut {
  animation: fadeOut 0.3s linear;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -ms-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.react-tiny-popover-container {
  z-index: 100;
}

@layer utilities {
  .text-shadow-stroke-yellow {
    text-shadow: -0 -1px 0 #fff0cf, 0 -1px 0 #fff0cf, -0 1px 0 #fff0cf,
      0 1px 0 #fff0cf, -1px -0 0 #fff0cf, 1px -0 0 #fff0cf, -1px 0 0 #fff0cf,
      1px 0 0 #fff0cf, -1px -1px 0 #fff0cf, 1px -1px 0 #fff0cf,
      -1px 1px 0 #fff0cf, 1px 1px 0 #fff0cf, -1px -1px 0 #fff0cf,
      1px -1px 0 #fff0cf, -1px 1px 0 #fff0cf, 1px 1px 0 #fff0cf;
  }
}

::-webkit-scrollbar {
  background: #312f2f;
  width: 0.88rem;
  height: 8px;
  border: none;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: #403e3e;
  border: none;
  cursor: pointer;
}
